.Gallery-Item img {
  width: 150px;
}

.Gallery-Item {
  width: 150px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 100px;
  padding: 10px;
}
.Gallery-Item:hover {
  border-radius: 17px;
  border: 2px solid rgba(102,102,102, 0.4);
  cursor: pointer;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(102, 102, 102, 0.4);
}

.Gallery-Item-Label {
  /* color: rgb(102, 102, 102); */
  font-weight: 700;
}