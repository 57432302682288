.Footer {
  display: flex;
  justify-content: center;
  align-items: center;
  /* position: sticky; */
  width: 100%;
  padding: 5px;
  background-color: rgb(102,102,102);
  color: white;
  font-family: custom;
}

.Footer p {
  margin: 0;
}