.Mobile-Menu-Container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100vh;
  width: 100vw;
  padding: 5px; 
  animation: fadeAndShift;
  animation-duration: 0.25s;
}

.Mobile-Nav-Item {
  height: 50px;
  /* flex: 1; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 10px;
  /* border: 2px solid gray; */
  /* background-color: aqua; */
}

.Mobile-Nav-Tittle {
  font-size: large;
  letter-spacing: 1px;
  /* font-family: custom; */
}

.Mobile-Nav-Header {
  font-family: custom;
  font-size: larger;
  padding-left: 10px
}

@keyframes fadeAndShift {
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}