.Products-Container {
  width: 100%;
  background-color: white;
}

.Products-Banner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 400px;
  background-image: url('banner.jpg');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: rgb(118, 135, 131);
}

.Product-Banner-Title {
  color: white;
  font-family: custom;
  font-size: 5em;
  letter-spacing: 5px;
  text-shadow: 0 0 10px rgb(70, 18, 70);
  text-align: center;
}

.Product-Cards-Section {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
}