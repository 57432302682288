.Slideshow-Container {
  width: 100%;
  border: 1px solid black;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  height: 400px;
  /* opacity: 0.8; */
}

.SlideShow-Image {
  background-position: center;
  background-repeat: no-repeat;
  background-color: rgb(118, 135, 131);
  animation-name: fadeinz;
  animation-duration: 3s;
  animation-timing-function: ease-in;
  animation-iteration-count: infinite;
  flex: 9;
  /* opacity: 0.8; */
  /* background-size: 20rem; */
}

.Left-Side {
  flex: 12;
  display: flex;
  background-color: rgb(118, 135, 131);
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.Main-Header {
  font-size: 44px;
  margin: 0;
}

.Main-Header-Mobile {
  font-size: 30px;
  margin: 0;
}

/* .Right-Side {
  flex: 1;
  display: flex;
  background-color: rgb(118, 135, 131);
  height: 100%;
} */

.White-Text {
  color: white;
}

@keyframes fadeinz {
  0% {
    opacity: 0.9;
  }
  25% {
    opacity: 0.9;
  }
  85% {
    opacity: 0.9;
  }
  100% {
    opacity: 0.5;
  }
}
