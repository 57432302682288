.Header {
  height: 100px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.Header-Title {
  font-size: 3rem;
  font-family: custom;
  letter-spacing: 4px;
  padding-left: 10px;
  color: rgb(102, 102, 102);
}

.each-slide > div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  height: 350px;
}

.each-slide span {
  padding: 20px;
  font-size: 20px;
  background: #efefef;
  text-align: center;
}

.Header-Logo {
  width: 100px;
}


.hours {
  /* width: 100%; */
  justify-self: flex-end;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: rgb(102, 102, 102);
  font-family: custom;
  margin-right: 20px;
  margin-left: 0;
  font-size: 12px;
}

.Mex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 150px;
}

.Hours-Row-Mobile {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 70px;
}

.Header-Mobile {
  height: 50px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-left: 10px;
}

.Header-Logo-Mobile {
  width: 100px;
}

.Header-Title-Mobile {
  font-size: 1.8rem;
  font-family: custom;
  padding-left: 10px;
  color: rgb(102, 102, 102);
}

.hours-mobile {
  justify-self: flex-end;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: rgb(102, 102, 102);
  font-family: custom;
  margin-left: 20px;
  font-size: 12px;
}