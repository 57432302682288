.Gallery-Container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 30px 0;
  /* color: rgb(102, 102, 102); */
}

.Gallery-Container h2 {
  color: rgb(102, 102, 102);
}

.Gallery-View {
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  /* overflow: scroll; */
  /* padding: 40px; */
}

.Gallery-View-Mobile {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  overflow: scroll; 
}