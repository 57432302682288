.Nav-Bar {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-family: custom;
  color: rgb(102, 102, 102);
  font-weight: bold;
  padding: 5px 0;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 1.3px;
  border-bottom:1px solid  rgba(102, 102, 102, 0.5);
}

.Nav-Item {
  cursor: pointer;
}

.Nav-Menu {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  z-index: 11;
  width: 60%;
}

.Nav-Menu-Container {
  position: absolute;
  width: 100%;
  background-color: white;
  height: 360px;
  animation-name: fade-in;
  animation-duration: 0.4s;
  animation-timing-function: ease-in-out;
  border:1px solid  rgba(102, 102, 102, 0.5);
  padding: 10px;
  z-index: 10;
  display: flex;
  justify-content: center;
}

.Nav-Menu-Left {
  height: 80%;
  border-right: 1.5px solid rgba(102, 102, 102, 0.5);
  padding-right: 2rem;
  align-self: center; 
  margin-right: 1rem;
}

.Menu-Thumbnail {
  width: 120px;
  margin-top: 10px;
}

.Nav-Menu-Right {
  height: 80%;
  /* border-right: 1.5px solid rgba(102, 102, 102, 0.5); */
  /* padding-right: 2rem; */
  /* align-self: center; */
  margin-left: 100px; 
}

.Nav-Menu-Middle {
  height: 80%;
  width: 100%;
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-right: 1rem;
}

.Menu-Header {
  padding: 5px;
  font-weight: 700;
  font-size: small;
  width: 200px;
  cursor: pointer;
}

.Brand-Item {
  font-family: custom;
  color: rgb(102, 102, 102);
  cursor: pointer;
  padding: 4px;
}

.Brand-Item:hover {
  color: black
}

.Divider {
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1px solid gray; */
  border-right: 1px solid gray;
  height: 15px;
  /* width: 1px; */
  background-color: gray;

  margin-right: 15px;
  margin-left: 15px;
  /* background-color: gray; */
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
